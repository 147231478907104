<template>
  <b-sidebar
      :visible="isEditProfileSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-edit-profile-sidebar-active', val)"
      @hidden="resetForm;tabIndex = 0"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Edit Profile
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"

      >
        <!-- Form -->

        <b-card>
          <b-tabs v-model="tabIndex" lazy>
            <b-overlay
                :show="sideBArLoading"
                rounded="sm"
            >
              <b-tab title="Personal Info">
                <div class="alert-body">
                  <PersonalInfo :user-data="userData" @savePersonalInfo="savePersonalInfo()"/>
                </div>
              </b-tab>

              <b-tab title="Professional Info">
                <div class="alert-body">
                  <ProfessionalInfo :professionalInfo="professionalInfo"
                                    @updateProfessionalInfo="(updatedQualification) => user.qualifications = updatedQualification"/>
                </div>
              </b-tab>
              <b-tab title="Unavailability">
                <div class="alert-body">
                  <Unavailability/>
                </div>
              </b-tab>
            </b-overlay>
          </b-tabs>

        </b-card>

      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import PersonalInfo from './personalinfo'
import ProfessionalInfo from './professionalinfo'

import {
  BAlert,
  BButton,
  BCard,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BOverlay,
  BSidebar,
  BTab,
  BTabs
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import profile from '@/apis/modules/profile'


export default {

  components: {
    PersonalInfo,
    ProfessionalInfo,
    Unavailability: () => import ('./unavailability'),
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BTab,
    BTabs,
    BCard,
    BOverlay,


    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCardActions
  },
  directives: {
    BCardActions,
    Ripple
  },

  props: {
    isEditProfileSidebarActive: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      professionalInfo: {},
      tabIndex: 0,
      userData: {},
      sideBArLoading: false
    }
  },

  methods: {
    savePersonalInfo () {
      this.toggle()
    },
    toggle () {
      this.$emit('toggleSideBars')
    },
    async loginUser () {
      try {
        this.userData = (await profile.getCurrentUser()).data.data
        this.professionalInfo = this.userData.qalifications || {}
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    resetForm () {

    }
  },
  mounted () {
    this.loginUser()
  }

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

</style>
<style lang="scss" scoped>
</style>

