<template>
  <div>
    <b-card no-body>
      <b-overlay
          :show="addLoader"
          rounded="sm"
      >
        <validation-observer
            ref="professionalInfoForm"
            #default="{ handleSubmit }"
        >
          <b-form
              class="auth-login-form mt-2"
          >
            <b-row class="">

              <!-- Qualification -->


              <b-col cols="12" md="12">
                <b-form-group
                    label="Qualification"
                    label-for="h-qualification-name"
                >
                </b-form-group>
              </b-col>

              <b-col class="mb-1" cols="12" md="12">
                <validation-provider
                    #default="{ errors }"
                    name="qualification"
                    rules="required"
                >
                  <v-select
                      v-model="professionalInfo.qualification_id"
                      :options="dropdowns.qualifications"
                      placeholder="Select Qualification"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <!-- Speciality -->
              <b-col cols="12" md="12">
                <b-form-group
                    label="Speciality"
                    label-for="h-speciality-name"
                >
                </b-form-group>
              </b-col>

              <b-col class="mb-1" cols="12" md="12">
                <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      name="speciality"
                      rules="required"
                  >
                    <v-select
                        v-model="professionalInfo.speciality_id"
                        :options="dropdowns.specialities"
                        placeholder="Select Speciality"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- IT System -->

              <b-col cols="12" md="12">
                <b-form-group
                    label="IT System"
                    label-for="IT system"
                >
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12">
                <b-form-group class="mb-1">
                  <validation-provider
                      #default="{ errors }"
                      name="IT system"
                      rules="required"
                      vid="ITSystem"
                  >
                    <v-select
                        v-model="professionalInfo.it_system_id"
                        :options="dropdowns.it_systems"
                        placeholder="Select IT System"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


              <b-col cols="12" md="12">

                <b-form-group
                    label="Smart Card Number"
                    label-for="SmartCardNumber"
                >
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12">
                <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      name="Smart card number"
                      rules="max_input"
                      vid="SmartCardNumber"
                  >
                    <b-form-input
                        id="SmartCardNumber"
                        v-model="professionalInfo.smart_card_number"
                        class="mb-1"
                        placeholder="Enter smart card number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="12">
                <b-form-group
                    label="Skills"
                    label-for="h-skills"
                >
                </b-form-group>
              </b-col>
              <b-col class="mb-3" cols="12" md="12">
                <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      name="IT system"
                      rules="required"
                  >
                    <v-select
                        v-model="professionalInfo.skills"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        multiple
                        placeholder="Add skills"
                        push-tags
                        taggable
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col md="12">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1"
                    type="submit"
                    variant="primary"
                    @click.prevent="submitForm"
                >
                  Save
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                >
        <span class="text-secondary">
          Cancel
        </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-card>

  </div>
</template>

<script>

import vSelect from 'vue-select'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BOverlay,
  BRow,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import profile from '@/apis/modules/profile'

export default {

  name: 'profile',
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    vSelect,
    BDropdownItem,
    BDropdown,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  props: {
    professionalInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      addLoader: false,
      dropdowns: {
        qualifications: [],
        specialities: [],
        it_systems: []
      },
      dir: 'ltr',
      selected: null,
      option: [{title: 'Dietology'}, {title: 'Food Processing '}, {title: 'Nutrition'}, {title: 'Biochemistry'}]


    }

  },
  methods: {
    async submitForm () {
      this.addLoader = true
      this.$refs.professionalInfoForm.validate().then(async success => {
        if (success) {
          let payload = {}
          payload.it_system_id = this.professionalInfo.it_system_id.value
          payload.qualification_id = this.professionalInfo.qualification_id.value
          payload.speciality_id = this.professionalInfo.speciality_id.value
          payload.skills = this.professionalInfo.skills
          payload.smart_card_number = this.professionalInfo.smart_card_number
          try {
            let updatedQualifications = (await profile.updateProfessionalInfo(payload, this.$route.params.id)).data.success
            this.$emit('updateProfessionalInfo', updatedQualifications)
            this.addLoader = false
            this.showSuccessMessage('Professional Info updated successfully')
          } catch (error) {
            this.convertAndNotifyError(error)
            this.addLoader = false
          }
        }
      })
      this.addLoader = false

    },
    async getQualifications () {
      this.addLoader = true
      let qualificationTypes = (await profile.getQualificationsList()).data.data
      this.dropdowns.qualifications = qualificationTypes.qualification.map((x) => ({
        value: x.id,
        label: x.name
      }))
      this.dropdowns.specialities = qualificationTypes.speciality.map((x) => ({
        value: x.id,
        label: x.name
      }))
      this.dropdowns.it_systems = qualificationTypes.it_system.map((x) => ({
        value: x.id,
        label: x.name
      }))
      this.addLoader = false
    }
  },
  async mounted () {
    await this.getQualifications()
    if(_.isEmpty(this.professionalInfo,true)) return
    this.professionalInfo.qualification_id = _.find(this.dropdowns.qualifications,{value:this.professionalInfo.qualification_id}) || this.professionalInfo.qualification_id
    this.professionalInfo.it_system_id = _.find(this.dropdowns.it_systems,{value:this.professionalInfo.it_system_id}) || this.professionalInfo.it_system_id
    this.professionalInfo.speciality_id = _.find(this.dropdowns.specialities,{value:this.professionalInfo.speciality_id}) || this.professionalInfo.speciality_id
  }

}
</script>

<style scoped>

</style>
