var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.addLoader,"rounded":"sm"}},[_c('validation-observer',{ref:"professionalInfoForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('b-row',{},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Qualification","label-for":"h-qualification-name"}})],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"qualification","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.dropdowns.qualifications,"placeholder":"Select Qualification"},model:{value:(_vm.professionalInfo.qualification_id),callback:function ($$v) {_vm.$set(_vm.professionalInfo, "qualification_id", $$v)},expression:"professionalInfo.qualification_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Speciality","label-for":"h-speciality-name"}})],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"speciality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.dropdowns.specialities,"placeholder":"Select Speciality"},model:{value:(_vm.professionalInfo.speciality_id),callback:function ($$v) {_vm.$set(_vm.professionalInfo, "speciality_id", $$v)},expression:"professionalInfo.speciality_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"IT System","label-for":"IT system"}})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":"IT system","rules":"required","vid":"ITSystem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.dropdowns.it_systems,"placeholder":"Select IT System"},model:{value:(_vm.professionalInfo.it_system_id),callback:function ($$v) {_vm.$set(_vm.professionalInfo, "it_system_id", $$v)},expression:"professionalInfo.it_system_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Smart Card Number","label-for":"SmartCardNumber"}})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Smart card number","rules":"max_input","vid":"SmartCardNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"SmartCardNumber","placeholder":"Enter smart card number"},model:{value:(_vm.professionalInfo.smart_card_number),callback:function ($$v) {_vm.$set(_vm.professionalInfo, "smart_card_number", $$v)},expression:"professionalInfo.smart_card_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Skills","label-for":"h-skills"}})],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12","md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"IT system","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","multiple":"","placeholder":"Add skills","push-tags":"","taggable":""},model:{value:(_vm.professionalInfo.skills),callback:function ($$v) {_vm.$set(_vm.professionalInfo, "skills", $$v)},expression:"professionalInfo.skills"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v(" Save ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_c('span',{staticClass:"text-secondary"},[_vm._v(" Cancel ")])])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }