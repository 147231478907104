<template>
  <div>
    <b-card no-body>

      <b-row class="">

        <!-- Image & Replace image button  -->

        <b-col cols="2">

                         <div class="d-md-flex flex-row mb-2  text-md-left text-center ">
                              <b-avatar  :src="userImage"
                                class="mt-2 mr-3 mr-md-0"
                                size="70px"/>
<!--                           <b-avatar v-else :src="form.photo_url"-->
<!--                                     class="mt-2 mr-3 mr-md-0"-->
<!--                                     size="70px"/>-->

                         </div>
        </b-col>
        <b-col cols="10">
          <div class=" mt-3 font-weight-bolder profile-text" v-if="changeImageButton">
            <b-button class="" variant="primary" @click="changeImage()">
              <span class="align-middle" >Change Image</span>
            </b-button>
            <b-button class="ml-1" variant="danger" @click="removeImage()">
              <span class="align-middle " >Remove Image</span>
            </b-button>

            <!--                              <b-row class="pl-0 ">-->
            <!--                                  <b-col class="mt-1 font-weight-light" cols="12">-->
            <!--                                    <span class="text-dark ">Maximum upload size 1MB</span>-->
            <!--                                  </b-col>-->

            <!--                              </b-row>-->
          </div>
          <div class="mt-2" v-if="!changeImageButton" >
            <b-form-file
                v-model="uploadImage"
                id="wildcard"
                accept="image/*"
            />
            <b-button class="float-right ml-1 mt-1" variant="" @click="changeImageButton = true">
              <span class="align-middle" >Cancel</span>
            </b-button>
            <b-button class="float-right mt-1" variant="primary" @click="uploadFile()" :disabled="uploadImage.length===0">
              <span class="align-middle" >Upload</span>
            </b-button>

          </div>

        </b-col>


        <!-- first name -->

        <b-col class=" mx-1  " cols="12" xl="12">
          <b-row>
            <b-col cols="12" md="12">

              <b-form-group
                  label="First Name"
                  label-for="h-first-name"
              >
                <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                >
                  <b-form-input id="h-first-name"
                                v-model="form.first_name"
                                class="mb-1"
                                placeholder="First Name"

                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


            <b-col cols="12" md="12">
              <b-form-group
                  label="Last Name"
                  label-for="h-lst-name"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                >
                  <b-form-input id="h-last-name"
                                v-model="form.last_name"
                                class="mb-1"
                                placeholder="Last Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group
                  label="Telephone"
                  label-for="h-telephone"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Telephone"
                    rules="required|tel|min_tel|max_tel"
                >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PhoneIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="icons-phone"
                      v-model="form.phone_number"
                      placeholder="(+44)"
                  />
                </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Email -->

            <b-col cols="12" md="12">
              <b-form-group
                  label="Email"
                  label-for="h-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|emailTest"
                >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="icons-mail"
                      v-model="form.email"
                      placeholder="example@.com"
                  />
                </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


            <!--       <b-col cols="12" md="12">-->
            <!--        <b-form-group-->
            <!--          label="Role"-->
            <!--          label-for="h-role-name"-->
            <!--        >-->
            <!--        </b-form-group>-->
            <!--      </b-col>-->
            <!--          <b-col cols="12" md="12">-->
            <!--           <b-form-group class="mb-1">-->
            <!--                    <v-select-->
            <!--                            label="title1"-->
            <!--                            placeholder="Select Role"-->
            <!--                            :options="[-->
            <!--                            {title1:'Dietician', value:'hdhdh'},-->
            <!--                            {title1:'Dietician 1', value:'hdhdh'},-->
            <!--                            {title1:'Dietician 2', value:'hdhdh'},-->
            <!--                            ]"-->
            <!--                    />-->
            <!--                </b-form-group>-->
            <!--      </b-col>-->

            <!--      <b-col cols="12" md="12">-->
            <!--        <b-form-group-->
            <!--          label="Telephone"-->
            <!--          label-for="h-telephone"-->
            <!--        >-->
            <!--        </b-form-group>-->
            <!--      </b-col>-->
            <!--          <b-col cols="12" md="12">-->
            <!--          <b-input-group class="input-group-merge mb-1">-->
            <!--                <b-input-group-prepend is-text>-->
            <!--                    <feather-icon icon="PhoneIcon" />-->
            <!--                 </b-input-group-prepend>-->
            <!--                 <b-form-input placeholder="Telephone" />-->
            <!--           </b-input-group>-->
            <!--      </b-col>-->


            <!--        <b-col cols="12" md="12">-->
            <!--        <b-form-group-->
            <!--          label="Email"-->
            <!--          label-for="h-email"-->
            <!--        >-->
            <!--        </b-form-group>-->
            <!--      </b-col>-->
            <!--          <b-col cols="12" md="12">-->
            <!--           <b-input-group class="input-group-merge mb-1">-->
            <!--      <b-input-group-prepend is-text>-->
            <!--        <feather-icon icon="MailIcon" />-->
            <!--      </b-input-group-prepend>-->
            <!--      <b-form-input placeholder="Email" />-->
            <!--    </b-input-group>-->
            <!--      </b-col>-->


            <!--        <b-col cols="12" md="12">-->
            <!--        <b-form-group-->
            <!--          label="NI Number"-->
            <!--          label-for="h-ninumber"-->
            <!--        >-->
            <!--        </b-form-group>-->
            <!--      </b-col>-->
            <!--          <b-col cols="12" md="12">-->
            <!--                <b-input-group class="input-group-merge mb-3">-->
            <!--                 <b-input-group-prepend is-text>-->
            <!--                      <feather-icon icon="PocketIcon" />-->
            <!--                 </b-input-group-prepend>-->
            <!--                   <b-form-input placeholder="NI Number" />-->
            <!--                </b-input-group>-->
            <!--      </b-col>-->


            <!-- submit and reset -->
            <b-col md="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  type="submit"
                  variant="primary"
                  @click="profileUpdate()"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="cancel()"
              >
        <span class="text-secondary">
          Cancel
        </span>
              </b-button>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>

import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BRow,
  BFormFile,
} from 'bootstrap-vue'
import profile from '@/apis/modules/profile'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import store from '../../../../store/profile/index'
import fileUploader from "@/constants/fileUploader";
import {imageBaseApiUrl} from '@/constants/config'


export default {
  name: 'profile',
  components: {
    store,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  mixins: [SuccessMessage, ErrorMessages],
  directives: {
    Ripple
  },
  data () {
    return {
      userImage:null,
      changeImageButton:true,
      uploadImage:[],
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        photo_url:''
      }
    }
  },
  props:{
    userData: {
      type: Object,
      required: true
    }
  },
  watch:{
    userData(val){
      this.userImage = this.getUserImage()
      this.form.first_name = val.first_name
      this.form.last_name = val.last_name
      this.form.email = val.email
      this.form.phone_number = val.phone_number
      this.form.photo_url = val.photo_url
    }
  },
  methods: {
    changeImage(){
      this.changeImageButton = !this.changeImageButton
    },
    async removeImage () {
      this.$swal({
        title: 'Are you sure you want to remove profile image?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {

        if (result.value) {
          await profile.profileRemove()

          // this.$swal({
          //   icon: 'success',
          //   title: 'Published!',
          //   customClass: {
          //     confirmButton: 'btn btn-success'
          //   }
          // })
        }
      })
    },
    async profileUpdate () {
      try {
        await profile.profileUpdate(this.form)
        this.showSuccessMessage('Profile updated')
        this.$emit('savePersonalInfo')
        await this.$store.dispatch('autoLogin')
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    cancel () {
      this.$emit('savePersonalInfo')
    },

    async uploadFile(){
      try {

        fileUploader.store(this.uploadImage, {
          progress: progress => {
            this.uploadProgress = Math.round(progress * 100)
          },
          'visibility': 'public-read',
          // baseURL: 'http://hyre-be-v2.test/api/mp',
          baseURL: `${imageBaseApiUrl}`,

        }).then(async response => {
          const key = response.key
          await this.setImageKeyToApi(key)
        })

      }catch (error) {
        this.convertAndNotifyError(error)


      }


    },
    async setImageKeyToApi (key) {

      (await profile.profileUpload({key: key, name:this.uploadImage.name}  ))

      // this.items[_.findIndex(this.items, {id:this.item_id})].item_image_key = key
      this.showSuccessMessage('Photo updated successfully')
      this.userImage = this.getUserImage(true)
      this.changeImageButton = true



      // setTimeout (() => {
      //   const new_src = `${this.api_url}/items/${this.item_id}/image?${Date.now()}`
      //   var image = document.getElementById(`${this.item_id}_img`);
      //   image.src = new_src
      //   this.item_id = null
      //   this.popupActiveImage = false
      // },500)
    },
  },
  mounted () {
  }

}
</script>

<style scoped>

</style>
