<template>
  <div>

    <!-- User Details -->
    <b-card>
      <b-row>
        <!-- User Avatar -->

        <b-col cols="12" xl="1">

          <b-avatar :src="getUserImage()"
                    class=" mr-3 mr-md-0"
                    style="padding:5px"
                    size="75px"
          />
        </b-col>
        <b-col cols="12" xl="11" class="p-0">
          <b-row>
            <!-- Name & Alert -->
            <b-col cols="12" xl="5">
              <div class="d-flex align-items-center ">
                <div class="d-md-flex flex-row text-center mr-1">
                  <h3 v-if="currentUser" class="mb-0 font-weight-700 mr-1">{{ currentUser.user.first_name }}
                    {{ currentUser.user.last_name }} </h3>
                  <b-badge v-for="(role,index) in roles" :key="index" class="mr-1" style="padding:8px"
                           variant="light-info">
                    <span class="text-dark font-weight-700"> {{ role }} </span>
                  </b-badge>
                </div>

                <div class="mt-1">
                  <b-alert show variant="primary">
                    <!--                    <small class="text-dark font-weight-700 mx-1 ">Occupational Therapist-->
                    <!--                    </small>-->
                  </b-alert>
                </div>
              </div>
            </b-col>

            <b-col class="pt-0 pb-2  pb-xl-0 " cols="12" xl="7">
              <b-row>
                <!-- Icon AND Values -->
                <b-col class="d-flex justify-content-center justify-content-xl-end" cols="12" md="11">

                  <!--                  &lt;!&ndash; 01) Hours worked &ndash;&gt;-->

                  <!--                  <div class="d-flex align-items-center pr-xl-4 pr-2">-->
                  <!--                    <b-avatar-->
                  <!--                        rounded-->
                  <!--                        variant="light-primary"-->
                  <!--                    >-->
                  <!--                      <feather-icon-->
                  <!--                          icon="ClockIcon"-->
                  <!--                          size="18"-->
                  <!--                      />-->
                  <!--                    </b-avatar>-->
                  <!--                    <div class="ml-1">-->
                  <!--                      <h4 class="mb-0 font-weight-700">-->
                  <!--                        180-->
                  <!--                      </h4>-->
                  <!--                      <small>Hours worked</small>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <!--                  &lt;!&ndash; 02) Earned  &ndash;&gt;-->
                  <!--                  <div class="d-flex align-items-center pr-xl-4 pr-2">-->
                  <!--                    <b-avatar-->
                  <!--                        rounded-->
                  <!--                        variant="light-success"-->
                  <!--                    >-->
                  <!--                      <feather-icon-->
                  <!--                          icon="CreditCardIcon"-->
                  <!--                          size="18"-->
                  <!--                      />-->
                  <!--                    </b-avatar>-->
                  <!--                    <div class="ml-1">-->
                  <!--                      <h4 class="mb-0 font-weight-700">-->
                  <!--                        £9,650.00-->
                  <!--                      </h4>-->
                  <!--                      <small>Earned </small>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  &lt;!&ndash; 03) Earned  &ndash;&gt;-->
                  <!--                  <div class="d-flex align-items-center pr-xl-4 pr-2">-->
                  <!--                    <b-avatar-->
                  <!--                        rounded-->
                  <!--                        variant="light-primary"-->
                  <!--                    >-->
                  <!--                      <feather-icon-->
                  <!--                          icon="CalendarIcon"-->
                  <!--                          size="18"-->
                  <!--                      />-->
                  <!--                    </b-avatar>-->
                  <!--                    <div class="ml-1">-->
                  <!--                      <h4 class="mb-0 font-weight-700">-->
                  <!--                        159-->
                  <!--                      </h4>-->
                  <!--                      <small>Shifts scheduled</small>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  &lt;!&ndash; 04) Staff added  &ndash;&gt;-->
                  <!--                  <div class="d-flex align-items-center pr-bx">-->
                  <!--                    <b-avatar-->
                  <!--                        rounded-->
                  <!--                        variant="light-success"-->
                  <!--                    >-->
                  <!--                      <feather-icon-->
                  <!--                          icon="UserPlusIcon"-->
                  <!--                          size="18"-->
                  <!--                      />-->
                  <!--                    </b-avatar>-->
                  <!--                    <div class="ml-1">-->
                  <!--                      <h4 class="mb-0 font-weight-700">-->
                  <!--                        89-->
                  <!--                      </h4>-->
                  <!--                      <small>Staff added </small>-->
                  <!--                    </div>-->

                  <!--                  </div>-->
                </b-col>

                <!-- Edit Button -->

                <b-col class=" pl-md-0  my-md-0" cols="12" md="1">
                  <b-button
                      v-b-tooltip.hover.top="'Edit Profile'"
                      class="btn-icon bg-white shadow-sm font-weight-bold"
                      variant="white"
                      @click="isEditProfileSidebarActive = true"
                  >
                    <feather-icon class="text-primary" icon="EditIcon" size="20"/>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- Account created  -->

          <b-row>
            <b-col cols="12">
              <label class="font-small-3 font-weight-light">Account created on
                {{ momentFormat(currentUser.user.created_at, 'dddd DD - MM - YYYY') }}</label>
            </b-col>


          </b-row>

          <!-- Tabs -->
          <b-row>
            <b-col cols="12">
              <b-tabs>

                <!-- 01) Personal Info -->

                <b-tab title="Personal Info">
                  <div class="alert-body">
                    <b-col cols="12   mt-1">
                                 <span class="mr-1">
                                          <feather-icon icon="PhoneIcon"/>
                                          {{ currentUser.user.phone_number }}
                                    </span>
                      <span class="mr-1">
                                        <feather-icon icon="MailIcon"/>
                                         {{ currentUser.user.email}}
                                    </span>
<!--                      <span class="mr-1">
                                           <feather-icon icon="PocketIcon"/>
                                            AP234567B
                                    </span>-->
                    </b-col>
                  </div>
                </b-tab>

                <!-- 02) Professional Info -->

                <!--                <b-tab title="Professional Info">-->
                <!--                  <div class="alert-body">-->

                <!--                    <b-row class="ml-1">-->

                <!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->

                <!--                        <div>-->
                <!--                          <small>Qualification</small>-->
                <!--                          <div>-->
                <!--                            <b-row class="pl-0 ">-->
                <!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
                <!--                                <h5 class="mb-0 font-weight-700">-->
                <!--                                  MRCGP-->
                <!--                                </h5>-->
                <!--                              </b-col>-->
                <!--                            </b-row>-->
                <!--                          </div>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->

                <!--                        <div>-->
                <!--                          <small>Speciality</small>-->
                <!--                          <div>-->
                <!--                            <b-row class="pl-0 ">-->
                <!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
                <!--                                <h5 class="mb-0 font-weight-700">-->
                <!--                                  Child Protection-->
                <!--                                </h5>-->
                <!--                              </b-col>-->
                <!--                            </b-row>-->
                <!--                          </div>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->

                <!--                        <div>-->
                <!--                          <small>IT System</small>-->
                <!--                          <b-row class="pl-0 ">-->
                <!--                            <b-col class="mt-1 font-weight-light" cols="12">-->
                <!--                              <h5 class="mb-0 font-weight-700">-->
                <!--                                EMIS WEB-->
                <!--                              </h5>-->
                <!--                            </b-col>-->
                <!--                          </b-row>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->

                <!--                        <div>-->
                <!--                          <small>Smart Card Number</small>-->
                <!--                          <b-row class="pl-0 ">-->
                <!--                            <b-col class="mt-1 font-weight-light" cols="12">-->
                <!--                              <h5 class="mb-0 font-weight-700">-->
                <!--                                123456789-->
                <!--                              </h5>-->
                <!--                            </b-col>-->
                <!--                          </b-row>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->

                <!--                        <div>-->
                <!--                          <small>Skills</small>-->

                <!--                          <div>-->
                <!--                            <b-row class="pl-0 ">-->
                <!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
                <!--                                <b-badge class="mr-1" variant="light-info">-->
                <!--                                  <span class="text-dark ">Dietology</span>-->
                <!--                                </b-badge>-->
                <!--                                <b-badge class="mr-1 my-1 my-md-0" variant="light-info">-->
                <!--                                  <span class="text-dark"> Food Processing</span>-->
                <!--                                </b-badge>-->
                <!--                                <b-badge class="mr-1" variant="light-info">-->
                <!--                                  <span class="text-dark"> Nutrition</span>-->
                <!--                                </b-badge>-->
                <!--                                <b-badge class="mr-1  mb-1 my-md-0" variant="light-info">-->
                <!--                                  <span class="text-dark"> Biochemistry</span>-->
                <!--                                </b-badge>-->
                <!--                              </b-col>-->

                <!--                            </b-row>-->
                <!--                          </div>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                    </b-row>-->

                <!--                  </div>-->
                <!--                </b-tab>-->

                <!-- 03) Availability -->

                <!--                <b-tab title="Availability">-->
                <!--                  <div class="alert-body">-->
                <!--                    <b-row>-->
                <!--                      <b-col cols="12" md="10">-->
                <!--                        <Available/>-->
                <!--                      </b-col>-->
                <!--                      <b-col cols="12" md="2"/>-->
                <!--                    </b-row>-->
                <!--                  </div>-->
                <!--                </b-tab>-->


              </b-tabs>
            </b-col>
          </b-row>

          <!-- Reset Password Button -->
          <b-row>
            <b-col cols="12">
              <b-row class="pl-0 ">
                <b-col class="mt-1 font-weight-light" cols="12" md="2">
                  <b-button
                      class="btn-block "
                      variant="primary"
                      @click="isEditSidebarActive = true"
                  >
                    <span class="align-middle">Reset Password</span>
                  </b-button>
                </b-col>

              </b-row>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </b-card>

<!--     Switch to-->




    <b-col class="mb-2" md="12">
      <div class="flex">
        <b-row>
          <b-col cols="4">
            <h3 class="font-weight-700">Switch to Practice</h3>
          </b-col>
          <b-col cols="8">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="float-right"
                size="sm"
                variant="primary"
                @click="createPractice()"

            >
              Create New Practice
            </b-button>


          </b-col>

        </b-row>

      </div>
    </b-col>

    <div>
      <b-overlay
          :show="tableLoading"
          rounded="sm"
      >
        <b-row>


          <!-- 01)  Wade Warren -->

          <b-col v-for=" practice in practices" :key="practice.id" cols="12" md="4">
            <a v-if=""

               :href="practice.team.roles.some(el =>el.name ==='practice-admin')?`${mpAppUrl}/scheduler?practice_id=${practice.id}`:`${mpAppUrl}/my-shifts?practice_id=${practice.id}`"
               target="_blank">
<!--              <a v-if=""-->
<!--                 :href=" practice.team.roles.some(el =>el.name ==='practice-admin')?`https://www.mp-v2.hyreapp.co.uk/scheduler?practice_id=${practice.id}`:`https://www.mp-v2.hyreapp.co.uk/my-shifts?practice_id=${practice.id}`"-->
<!--                 target="_blank">-->

<!--                          <a v-if=""-->
<!--                             :href=" practice.team.roles.some(el =>el.name ==='practice-admin')?`https://www.hyre-mp.locumboxapp.com/scheduler?practice_id=${practice.id}`:`https://www.hyre-mp.locumboxapp.com/my-shifts?practice_id=${practice.id}`"-->
<!--                             target="_blank">-->


              <!--            :href="`https://www.hyre-mp.locumboxapp.com/scheduler?practice_id=${practice.id}`"-->
              <!--            :href="`http://mp.hyre-be-v2.test:8081/scheduler?practice_id=${practice.id}`"-->
              <!--            http://mp.hyre-be-v2.test:8081-->
              <!--            :href="`${config.mpAppUrl}/scheduler?practice_id=${practice.id}`"-->

              <b-card class="">
                <div class="d-md-flex flex-row  text-md-left text-center ">
                  <img alt="" class="img-fluid" src="@/assets/images/pages/iconac.png">

                  <div class="ml-1 mt-1 font-weight-bolder profile-text">
                    <div class="d-flex align-items-center ">

                      <div>
                        <h4 class="mb-0 font-weight-700 ">{{ practice.name }} </h4>

                      </div>


                    </div>
                    <b-row>
                      <b-col cols="12">
                        <label class="font-small-3 font-weight-light">{{ practice.type.name }}</label>


                      </b-col>
                      <b-col cols="12">
                        <!--                    <div class="" v-for="role in practice.team.roles">-->

                        <!--                      <b-badge class="ml-1" style="padding:8px" variant="light-info"  v-for="role in practice.team.roles">-->
                        <!--                        <span class="text-dark font-weight-700"> {{role.name}} </span>-->
                        <!--                      </b-badge>-->

                        <!--&lt;!&ndash;                      <b-badge class="" style="padding:2px" variant="info">&ndash;&gt;-->
                        <!--&lt;!&ndash;                        <span class="text-white font-weight-700"> {{role.name}} </span>&ndash;&gt;-->
                        <!--&lt;!&ndash;                      </b-badge>&ndash;&gt;-->
                        <!--                    </div>-->
                        <b-badge v-for="role in practice.team.roles" :key="role.id" class="mr-1" style="padding:8px"
                                 variant="light-info">
                          <span class="text-dark font-weight-700"> {{ role.name }} </span>
                        </b-badge>


                      </b-col>
                      <!--                  <b-col cols="12">-->
                      <!--                    <label class="font-small-3 font-weight-light" v-if="practice.pivot.is_admin">Admin</label>-->
                      <!--                    <label class="font-small-3 font-weight-light" v-if="practice.pivot.is_salaried_staff">Salaried Staff</label>-->
                      <!--                  </b-col>-->

                      <b-col cols="12" v-if="!(practice.team.roles.filter(role => role.name ==='salaried-staff').length !==0 && practice.team.roles.length === 1)">
<!--                        <span class="font-weight-700"> 3 </span>-->
<!--                        <span class="mr-1"> connected practices</span>-->
<!--                        <span class="font-weight-700">1</span>-->
<!--                        <span>connected PCNs</span>-->

                      </b-col>
                      <!--                  <b-col cols="12">-->
                      <!--                    <b-button-->
                      <!--                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                      <!--                        size="sm"-->
                      <!--                        variant="primary"-->
                      <!--                        class="mr-1 mt-1"-->
                      <!--                    >-->
                      <!--                     View-->
                      <!--                    </b-button>-->
                      <!--                    <b-button-->
                      <!--                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                      <!--                        size="sm"-->
                      <!--                        variant="primary"-->
                      <!--                        class="mr-1 mt-1"-->
                      <!--                        @click="editPractice(practice.id)"-->
                      <!--                    >-->
                      <!--                    Edit-->
                      <!--                    </b-button>-->
                      <!--                    <b-button-->
                      <!--                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                      <!--                      size="sm"-->
                      <!--                      variant="primary"-->
                      <!--                      class="mr-1 mt-1"-->
                      <!--                  >-->
                      <!--                    Go To Dashboard-->
                      <!--                  </b-button>-->
                      <!--                  </b-col>-->
                    </b-row>
                  </div>
                </div>
              </b-card>
            </a>
          </b-col>

        </b-row>
      </b-overlay>


    </div>
    <div class="ml-1">
      <h3 class="font-weight-700 ">I want to register as a locum</h3>
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class=" mb-3"
          size="sm"
          variant="primary"
          @click="locumRegister()"
          v-if="!showLocumRegistered"

      >
        Register locum
      </b-button>

     <a
         v-else
          href="http://locum.hyre-be-v2.test:8084/shifts"
          target="_blank">

<!--            <a v-else-->
<!--                 href="https://www.locum-v2.hyreapp.co.uk/shifts"-->
<!--                  target="_blank">-->
<!--              <a v-else-->
<!--                   href="https://www.hyre-locum.locumboxapp.com/shifts"-->
<!--                    target="_blank">-->
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=" mb-3"
            size="sm"
            variant="primary"

        >
          Go Locum Dashboard
        </b-button>
      </a>
    </div>
    <b-modal
    id="modal-center"
    centered
    title="Locum Register"
    hide-footer
    size="lg"
    v-model="locumregisterModel"
    >
    <b-row class="mx-1">
      <b-col cols="12" md="12">
        <validation-observer ref="CreatePracticeForm">
          <div class="mb-1">
            <b-form-file
                placeholder="CV"
                drop-placeholder="Drop file here..."
                no-drop
                class="mb-1"
                v-model="files.cv"
            />
            <b-form-file
                placeholder="CCT"
                drop-placeholder="Drop file here..."
                no-drop
                class="mb-1"
                v-model="files.cct"
            />
            <b-form-file
                placeholder="Passport"
                drop-placeholder="Drop file here..."
                no-drop
                class="mb-1"
                v-model="files.passport"
            />
            <b-form-file
                placeholder="DBS"
                drop-placeholder="Drop file here..."
                no-drop
                v-model="files.dbs"
            />

          </div>
          <b-form-group class="mb-2">
            <label>Job Role</label>
            <validation-provider
                #default="{ errors }"
                name="Job role"
                rules="required"
            >
              <v-select
                  v-model="job_role_id"
                  :options="jobRoleOption"
                  placeholder="Select Job role"
                  label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>


          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1"
              type="submit"
              variant="primary"
              @click="locumRegisterFormSubmit()"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="resetData()"
          >
        <span class="text-secondary">
          Reset
        </span>
          </b-button>

          <!--            </b-form>-->

        </validation-observer>

      </b-col>


    </b-row>

    </b-modal>


    <edit-practice ref="editPractice"/>
    <create-practice ref="createPractice"
                     @practiceCreated="practiceCreated()"/>
    <locum-register ref="locumRegister" />

    <EditSideBar
        :is-edit-sidebar-active.sync="isEditSidebarActive"
        @toggleSideBars="toggleSideBarsa()"
    />
    <EditProfileSideBar
        :is-edit-profile-sidebar-active.sync="isEditProfileSidebarActive"
        @toggleSideBars="toggleSideBars()"
    />

  </div>
</template>

<script>
import Available from '@/views/dashboard/component/availability-table'
import EditSideBar from '@/views/dashboard/component/reset-password'
import EditProfileSideBar from '@/views/dashboard/component/edit-profile/edit-profile-sidebar'
import profile from '@/apis/modules/profile'
import Practice from "@/apis/modules/Practice";

import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import EditPractice from "@/views/dashboard/component/edit-practice";


import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BLink,
  BRow,
  BAlert,
  BTabs,
  BTab,
  BOverlay,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BFormDatepicker,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea,
  BCardBody,
  BCardHeader,
  BMedia,
  BTable,
  BFormSelect,
  BPagination,
  BDropdown, BDropdownItem, BListGroup, BListGroupItem, BModal, BFormFile, VBTooltip
} from 'bootstrap-vue'
import BCardActions from "@core/components/b-card-actions/BCardActions";
import Ripple from "vue-ripple-directive";
import CreatePractice from "@/views/dashboard/component/create-practice";
// import config from "@/constants/config";
import {mapGetters} from "vuex";
import LocumRegister from "@/views/dashboard/locum/locum-register";
import {mpAppUrl} from '@/constants/config'
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";

export default {
  name: 'profile-image-bar',
  components: {
    LocumRegister,
    CreatePractice,
    EditPractice,
    Available,
    EditProfileSideBar,
    EditSideBar,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BOverlay,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,

    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,

    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,

    BCardBody,
    BCardHeader,
    BMedia,
    BTable,
    BFormSelect,
    BPagination,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormFile,


    VBTooltip,

  },
  directives: {
    BCardActions,
    Ripple,
    'b-tooltip': VBTooltip
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],

  data() {
    return {
      job_role_id:[],
      jobRoleOption:[],
      locumregisterModel:false,
      mpAppUrl,
      showLocumRegistered:false,
      isEditSidebarActive: false,
      isEditProfileSidebarActive: false,
      user: '',
      practices: '',
      tableLoading: false,
      files:{
        cv:[],
        cct:[],
        passport:[],
        dbs:[]
      },
      roles: [],

    }
  },
  methods: {
    async practiceCreated() {
      await this.getPracticeAndNetwork()
    },
    async editPractice() {
      await this.$refs.editPractice.showModel(true)

    },
    async locumRegister() {
     this.locumregisterModel = true
      await this.jobRoleList()

    },
    async locumRegisterFormSubmit(){
      // await this.$refs.locumRegister.showModel(true)
      this.$swal({
        title: 'Are you sure you want to register locum GP?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, register',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {

        if (result.value) {
          const payload ={
            job_role_id: this.job_role_id.id
          }
          const locumResponse = await Practice.registerLocum(payload)
          this.showSuccessMessage('locum register successful')
          this.isUserLocum()


          // this.$swal({
          //   icon: 'success',
          //   title: 'Published!',
          //   customClass: {
          //     confirmButton: 'btn btn-success'
          //   }
          // })
        }
      })
    },

    async isUserLocum(){
      try {
        const response = await Practice.checkUserIsLocum()

        this.showLocumRegistered = response.data.data.success

      }catch (error){
        this.convertAndNotifyError(error)
      }

    },
    async createPractice() {
      await this.$refs.createPractice.showModel(true)
    },
    toggleSideBars() {
      this.isEditProfileSidebarActive = !this.isEditProfileSidebarActive
      this.getUserInfo()
    },
    toggleSideBarsa() {
      this.isEditSidebarActive = !this.isEditSidebarActive
    },
    async getPracticeAndNetwork() {
      try {
        this.tableLoading = true
        const response = await Practice.getPractices()
        this.practices = response.data.data

        this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async jobRoleList (){
      try {
        const practiceListResponse =  await Practice.getJobRoleList()
        this.jobRoleOption = practiceListResponse.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },

  computed: {
    ...mapGetters(['currentUser']),

  },
  mounted() {
    this.getPracticeAndNetwork()
    this.isUserLocum()
    this.roles = this.currentUser.user.job_roles.map((x) => x.name)
    this.roles = _.values(_.uniq(_.map( this.roles)))
  }
}

</script>


<style scoped>

</style>
