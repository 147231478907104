import api from '../axios'

export default {
    getPractices: payload => api.get('api/practices'),
    createPractice: payload => api.post('api/practices',payload),
    getPracticeList: payload => api.get('api/practice-types'),


    //locum
    registerLocum: (payload) => api.post('api/locum/register', payload),
    checkUserIsLocum: payload => api.get('api/locum/current'),
    getJobRoleList: payload => api.get('api/job-roles?page=1&paginate=49'),







}
