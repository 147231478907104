<template>
  <b-sidebar
      :visible="isEditSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-edit-sidebar-active', val)"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Reset Password
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>


      <!-- Form -->

      <b-row class="mx-1">

        <b-col class="mt-2" cols="12" md="12">
          <b-form-group>
            Your new password must be different from previously used passwords.
          </b-form-group>
        </b-col>
        <!-- Current Password -->


        <b-col cols="12" md="12">
          <validation-observer ref="resetPasswordForm">
            <b-form @submit.prevent>
              <b-form-group class="">
                <label for="basic-password1">Current Password</label>
                <validation-provider
                    #default="{ errors }"
                    name="Old-Password"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="basic-password1"
                        v-model="form.old_password"
                        :type="passwordFieldType"
                        placeholder="Enter Current Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- New Password -->


              <!--              <b-col cols="12" md="12">-->
              <b-form-group>
                <label for="basic-password2">New Password</label>
                <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="basic-password2"
                        v-model="form.password"
                        :type="passwordFieldType"
                        placeholder="Enter New Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--              </b-col>-->


              <!-- Confirm Password -->

              <!--              <b-col cols="12" md="12">-->
              <b-form-group>
                <label for="basic-password3">Confirm Password</label>
                <validation-provider
                    #default="{ errors }"
                    name="Confirm-password"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="basic-password3"
                        v-model="form.password_confirmation
"
                        :type="passwordFieldType"
                        placeholder="Enter New Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--              </b-col>-->
              <!-- submit and reset -->
              <!--              <b-col class="mt-2" md="12">-->
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  type="submit"
                  variant="primary"
                  @click="resetPasswordClick()"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="resetData()"
              >
        <span class="text-secondary">
          Reset
        </span>
              </b-button>
              <!--              </b-col>-->


            </b-form>

          </validation-observer>
        </b-col>


      </b-row>


    </template>
  </b-sidebar>
</template>

<script>

import {
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea

} from 'bootstrap-vue'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import profile from '@/apis/modules/profile'


export default {
  components: {

    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,


    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCardActions
  },
  mixins: [togglePasswordVisibility, SuccessMessage, ErrorMessages],
  data() {
    return {
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  directives: {
    BCardActions,
    Ripple,
  },

  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },
  methods: {
    resetForm(){

    },
    resetData() {
      this.form = {
            old_password: '',
            password: '',
            password_confirmation: '',
      }

    },
    toggle() {
      this.$emit('toggleSideBars')
    },
    async resetPasswordClick() {
      if (await this.$refs.resetPasswordForm.validate()) {
        try {
          await profile.resetPassword(this.form)
          this.showSuccessMessage('Password reset successful')
          this.toggle()

        } catch (e) {
          // this.showErrorMessage(e)
          this.convertAndNotifyError(e)
        }
      }
    },
    cancel(){
      this.toggle()
    }
  },

}

</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

</style>
<style lang="scss" scoped>
</style>

